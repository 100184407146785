import React from "react";
const enharmonic_equivalents = {
  "c#": "db",
  "d#": "eb",
  "f#": "gb",
  "g#": "ab",
  "a#": "bb",
};
const AnswerDisplay = ({ showAnswer, answer, playAgain }) => {
  // Only render the content if showAnswer is true
  if (!showAnswer) return null;
  let updatedAnswer = answer.replace("2", "").replace("1", "");

  const playSound = (loc) => {
    const sound = new Audio(
      `/sounds/${enharmonic_equivalents[loc] || loc}.mp3`
    );
    sound
      .play()
      .catch((error) => console.error("Error playing the sound:", error));
  };
  return (
    <div className="mt-auto mb-8 flex justify-center items-center w-full">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-auto">
        <p className="text-5xl text-center font-bold">{`${updatedAnswer}`}</p>
        <div className="flex justify-center space-x-4">
          <button
            onClick={playAgain}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Play Again
          </button>
          <button
            onClick={() => playSound(updatedAnswer.toLowerCase())}
            className="mt-4 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          >
            Play Sound
          </button>
        </div>
      </div>
    </div>
  );
};

export default AnswerDisplay;
