import React from "react";

const ScaleSelector = ({ scales, selectedScale, onSelectScale }) => (
  <div className="flex flex-row justify-center overflow-x-auto space-x-4 py-2">
    {Object.keys(scales).map((scale) => (
      <button
        key={scale}
        value={scale}
        onClick={() => onSelectScale(scale)}
        className={`px-4 py-2 rounded-lg text-white font-medium focus:outline-none transition duration-150 ease-in-out ${
          selectedScale === scale
            ? "bg-green-500"
            : "bg-blue-400 hover:bg-blue-500"
        }`}
      >
        {scale}
      </button>
    ))}
  </div>
);

export default ScaleSelector;
