// useTimer.js
import { useState, useEffect } from "react";

const useTimer = (callback, delay) => {
  const [timerActive, setTimerActive] = useState(false);

  useEffect(() => {
    let timer = null;
    if (timerActive) {
      timer = setTimeout(() => {
        callback(); // Trigger the callback after delay
        setTimerActive(false); // Reset the timer state
      }, delay);
    }

    return () => clearTimeout(timer); // Cleanup on unmount or reset
  }, [callback, delay, timerActive]);

  const resetTimer = () => {
    setTimerActive(true);
  };

  return resetTimer;
};

export default useTimer;
