// App.js
import React, { useState, useEffect } from "react";
import ScaleSelector from "./components/ScaleSelector";
import NoteDisplay from "./components/NoteDisplay";
import AnswerDisplay from "./components/AnswerDisplay";
import useTimer from "./hooks/useTimer";

const scales = {
  "C Major": ["D", "C", "A", "B", "G", "E", "F"],
  "G Major": ["C", "F#", "D", "G", "B", "E", "A"],
  "D Major": ["B", "A", "C#", "G", "E", "D", "F#"],
  "A Major": ["F#", "G#", "E", "D", "C#", "A", "B"],
  "E Major": ["D#", "C#", "G#", "F#", "B", "A", "E"],
  "B Major": ["G#", "F#", "D#", "B", "C#", "E", "A#"],
  // "F# Major": ["F#", "G#", "A#", "B", "C#", "D#", "E#"],
  // "C# Major": ["C#", "D#", "E#", "F#", "G#", "A#", "B#"],
  "F Major": ["Bb", "E", "A", "F", "C", "D", "G"],
  "Bb Major": ["F", "G", "D", "A", "Bb", "C", "Eb"],
  "Eb Major": ["D", "Bb", "G", "Ab", "F", "C", "Eb"],
  "Ab Major": ["F", "Db", "C", "Bb", "Eb", "Ab", "G"],
  "Db Major": ["F", "Bb", "Db", "Gb", "Ab", "Eb", "C"],
  // "Gb Major": ["Gb", "Ab", "Bb", "Cb", "Db", "Eb", "F"],
  // "Cb Major": ["Cb", "Db", "Eb", "Fb", "Gb", "Ab", "Bb"],
};

const getRandomNote = (scale) => {
  const notes = scales[scale];
  const weights = [2, 2, 2, 1, 3, 1, 1]; // Adjust weights for each number
  const weightedNotes = notes.flatMap((note, index) =>
    Array(weights[index]).fill(note)
  );
  const randomIndex = Math.floor(Math.random() * weightedNotes.length);
  const note = weightedNotes[randomIndex];
  return (
    note +
    (note.includes("b") || note.includes("#")
      ? Math.floor(Math.random() * 2 + 1)
      : "")
  );
};

const App = () => {
  const [selectedScale, setSelectedScale] = useState("C Major");
  const [currentNote, setCurrentNote] = useState(getRandomNote(selectedScale));
  const [showAnswer, setShowAnswer] = useState(false);

  const showNote = () => {
    setCurrentNote(getRandomNote(selectedScale));
    setShowAnswer(false); // Initially don't show the answer
  };

  // Setup the timer hook
  const resetTimer = useTimer(() => setShowAnswer(true), 5000);

  // Start the game whenever the selectedScale changes
  useEffect(() => {
    showNote();
    resetTimer();
  }, [selectedScale]);

  const playAgain = () => {
    showNote();
    resetTimer();
  };

  return (
    <div className="bg-blue-200 text-gray-800 min-h-screen flex flex-col items-center justify-start gap-2">
      <h1 className="text-4xl font-bold mb-8">Music Notes Guessing Game</h1>
      <div className="container mx-auto px-4">
        <ScaleSelector
          scales={scales}
          selectedScale={selectedScale}
          onSelectScale={setSelectedScale}
        />
        <NoteDisplay note={currentNote} scale={selectedScale} />
        <AnswerDisplay
          showAnswer={showAnswer}
          answer={currentNote}
          playAgain={playAgain}
        />
      </div>
    </div>
  );
};

export default App;
