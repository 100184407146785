import React from "react";

const NoteDisplay = ({ note, scale }) => {
  const encodedNote = encodeURIComponent(note);

  return (
    <div
      className={`transition-opacity duration-500 ${
        note ? "opacity-100" : "opacity-0"
      } flex flex-col items-center justify-center w-full h-full`}
    >
      <h2 className="text-2xl font-bold mb-4">Guess the Note</h2>
      {note && (
        <img
          src={`/assets/${scale}/${encodedNote}.svg`}
          alt={note}
          className="w-3/5 min-w-96 h-72 object-fill mx-auto" // Adjusted class for demonstration
          // Ensures dimensions are filled as specified
        />
      )}
    </div>
  );
};

export default NoteDisplay;
